/** Specifies IDs from the delivery agreement
 * Question IDs are named 'id' + a descriptive name for the question
 * Answer IDs are named 'id' + a descriptive name for the question
 * + 'Answer' + a descriptive name for the answer
 * Some IDs can be accessed through corresponding enums
*/
const deliveryAgreementIds = {
  idDeliveryDate: '8b238ee9-9354-41ae-90ce-6401b6bf4a21',
  // Criteria weights:
  idSolutionDescriptionWeight: '59aa013c-54ae-4246-bdb4-e09c2ddc1271',
  idFunctionalityWeight: 'b9a9d4bf-28a3-416a-8403-00dcc15ec975',
  idImplementationWeight: '0c69dd27-f003-493d-b360-6173027cd47b',
  idOrganisationCooperationWeight: '25cdca8f-e5f5-4b1e-b043-6d179359f400',
};
export default deliveryAgreementIds;
