import { TemplateQuestionPanelState, Tab, TabType, DeepQuestionnaire } from '@dims/components';

const customerClarificationTabs: Tab[] = [
  {
    title: 'Ydelsesområder',
    section: 0,
    panelState: TemplateQuestionPanelState.INIT,
  },
  {
    title: 'Ydelsesspecifikke afklaringer',
    section: 1,
    panelState: TemplateQuestionPanelState.INIT,
  },
  {
    title: 'Tværgående afklaringer',
    section: 2,
    panelState: TemplateQuestionPanelState.INIT,
  },
];

const customerSpecificationTabs: Tab[] = [
  {
    title: 'Funktionelle krav',
    section: 3,
    panelState: TemplateQuestionPanelState.INIT,
  },
  {
    title: 'Nonfunktionelle krav',
    section: 4,
    panelState: TemplateQuestionPanelState.INIT,
  },
  {
    title: 'Evalueringskrav',
    section: 5,
    panelState: TemplateQuestionPanelState.INIT,
  },
  {
    title: 'Tilbudsliste',
    section: 6,
    panelState: TemplateQuestionPanelState.INIT,
  },
];

function supplierSpecificationTabs(p: [number, number]) {
  return [
    {
      title: 'Svar på nonfunktionelle krav',
      section: p[0],
      panelState: TemplateQuestionPanelState.INIT,
    },
    {
      title: 'Svar på evalueringskrav',
      section: p[1],
      panelState: TemplateQuestionPanelState.INIT,
    },
  ];
}

function supplierOfferTabs(p: number) {
  return [
    {
      title: 'Afgiv priser',
      section: p,
      panelState: TemplateQuestionPanelState.INIT,
    },
  ];
}

const nonFunctionalSectionKey = '0207NonFunctionalSection';
const evaluationSectionKey = '0207EvaluationSection';
const priceSectionKey = '0207PriceSection';
let supplierOfferTabNumber = 3;
let supplierSpecificationTabNumbers: [number, number] = [1, 2];

export function setSupplierSpecificationTabs(offerQuestionnaire: DeepQuestionnaire|null):number[] {
  supplierSpecificationTabNumbers = [offerQuestionnaire?.questions.findIndex((q) => q.key === nonFunctionalSectionKey) ?? 1,
    offerQuestionnaire?.questions.findIndex((q) => q.key === evaluationSectionKey) ?? 2];
  return supplierSpecificationTabNumbers;
}

export function setSupplierPriceTab(offerQuestionnaire: DeepQuestionnaire|null):number {
  supplierOfferTabNumber = offerQuestionnaire?.questions.findIndex((q) => q.key === priceSectionKey) ?? 3;
  return supplierOfferTabNumber;
}

const questionnaireTabConfig = {
  getTabs: (type: TabType): Tab[] => {
    switch (type) {
      case 'CustomerClarification':
        return customerClarificationTabs;
      case 'CustomerSpecification':
        return customerSpecificationTabs;
      case 'SupplierSpecification':
        return supplierSpecificationTabs(supplierSpecificationTabNumbers);
      case 'SupplierOffer':
        return supplierOfferTabs(supplierOfferTabNumber);
      default:
        return [];
    }
  },
};

export default questionnaireTabConfig;
