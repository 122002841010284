<template>
  <v-container fluid>
    <TenderMaterialIntroText v-if="!readonly" :tender="tender">
      <template #info_steps>
        <p class="mb-1">Sådan gør du:</p>
        <ol>
          <li>Definér dit behov i <strong>behovsafdækningen</strong></li>
          <li>Angiv dine krav i <strong>kravspecifikationen</strong></li>
          <li>Færdiggør <strong>leveringskontrakt</strong>, <strong>opfordring til tilbud</strong> og <strong>krav til CSR</strong> i én arbejdsgang</li>
          <li>Tilføj evt. eget CSR-bilag under <strong>Tilføj egne +</strong></li>
          <li>Marker dokumenternes status som <strong>Færdig</strong> for at aktivere knappen <strong>Godkend og offentliggør materiale</strong></li>
          <li>Kontroller og offentliggør dit materiale ved at klikke på <strong>Godkend og offentliggør materiale</strong></li>
        </ol>
      </template>
    </TenderMaterialIntroText>
    <TenderMaterialTableV2
      :documentList="documentList"
      :showFileInputMenu="clarificationDone"
      :tender="tender"
      :uploadModel="uploadModel"
      @setDocumentDoneStatus="setDocumentDoneStatus"
      @removeFile="removeFile"
      @edit="onEdit($event)"
      @preview="onPreview($event)"
    >
      <template #editRequestForOffer="{ activatorType }">
        <RequestForOfferDialog
          :activatorType="activatorType"
          :tender="tender"
        />
      </template>
      <template #clarificationInfo>
        <SimpleInfoDialog title="Behovsafdækning">
          <p>I behovsafdækningen skal du tage stilling til, hvilke overordnede kategorier du vil købe ind i, samt hvilke underordnede services der skal indgå i anskaffelsen.</p>
          <p>Du bliver bedt om at tage stilling til en række spørgsmål, der lægger rammerne for det efterfølgende materiale. Alle dine valg følger automatisk med til de efterfølgende trin i processen.</p>
          <p>Når du har udfyldt behovsafdækningen, får du en samlet rapport, der viser hvilke bilag, der er relevante for din anskaffelse, og hvilke tilpasninger SKI anbefaler.</p>
          <p>Når du er klar til at gå videre til kravspecifikationen, ændrer du status for behovsafdækning fra <strong>Kladde</strong> til <strong>Færdig</strong>.</p>
          <p>Brug de tre prikker til højre for <strong>Angiv status</strong> for at se preview og downloade behovsafdækningen.</p>
        </SimpleInfoDialog>
      </template>
      <template #specificationInfo>
        <SimpleInfoDialog title="Kravspecifikation">
          <p>
            Den digitale kravspecifikation guider dig til at specificere dit behov på en overskuelig måde. Kravspecifikationen indeholder de gængse krav og behov på markedet og giver dig mulighed for at supplere med egne krav.
          </p>
          <p>
            Kravspecifikationen gemmes automatisk undervejs og dine valg, rettelser og informationer bliver automatisk overført til alle relevante dokumenter og bilag.
          </p>
          <p>
            I <b>’Preview’</b>-funktionen øverst i kravspecifikationen kan du løbende se og skifte mellem bilagene. Du kan også downloade bilagene enkeltvis eller samlet.
          </p>
          <p>
            Brug <b>’Vis som leverandør’</b>-funktionen i kravspecifikationen til løbende at se tilbudslisten, som den vil blive præsenteret for leverandøren. Du kan også agere leverandør og testudfylde tilbudslisten.
          </p>
          <p>
            Den udfyldte kravspecifikation bliver automatisk til leverandørens tilbudsliste og bruges efterfølgende i den automatiske evaluering.
          </p>
          <p>
            Leverandørerne kan først se tilbudslisten, når du offentliggør indkøbet.
          </p>
          <p>
            Klik på de tre prikker til højre for <b>'Angiv status'</b> for at se en forhåndsvisning og downloade kravspecifikationen.
          </p>
          <p>
            Hvis du har spørgsmål eller brug for rådgivning, er du velkommen til at kontakte os.
          </p>
        </SimpleInfoDialog>
      </template>
      <template #requestForOfferInfo>
        <SimpleInfoDialog title="Leveringskontrakt og opfordring til tilbud mv.">
          <p>
            Du bliver guidet til at udfylde din leveringskontrakt og opfordring til tilbud samt generere CSR bilag i én arbejdsgang.
          </p>
          <p>
            Når du har afgivet alle de nødvendige informationer, bliver dokumenterne genereret automatisk. Informationerne bliver brugt senere i den digitalt understøttede evaluering.
          </p>
          <p>
            <b>CSR-bilaget</b> er obligatorisk, men du kan vælge, om du vil bruge SKI’s bilag eller dit eget. Hvis du vælger at bruge dit eget bilag, skal kravene være tilsvarende eller skærpede i forhold til kravene i SKI’s CSR-bilag. Du tilføjer eget bilag under <b>’Tilføj egne +’</b>.
          </p>
          <p>
            Du kan løbende se og skifte mellem leveringskontrakt, opfordring og CSR i <b>’Preview’</b>-funktionen øverst i systemet.
          </p>
          <p>
            Når du er færdig med dokumenterne, ændrer du status til <b>’Færdig’</b> og aktiverer knappen <b>’Godkend og offentliggør materiale’</b>.
          </p>
          <p>
            <b>Bemærk:</b> Når du trykker på <b>’Godkend og offentliggør materiale’</b>, offentliggør du <b>ikke</b> for leverandørerne endnu. Det sker først i næste trin, når du har kontrolleret dit materiale og er klar.
          </p>
          <p>
            Det er kun de leverandører, der opfylder dit valg af tildelingskriterie, der får mulighed for at afgive tilbud.
          </p>
          <p>
            Klik på de tre prikker til højre for <b>'Angiv status'</b> for at se og downloade bilagene enkeltvis eller samlet.
          </p>
          <p>
            Hvis du har spørgsmål eller brug for rådgivning, er du velkommen til at kontakte os.
          </p>
        </SimpleInfoDialog>
      </template>
    </TenderMaterialTableV2>
    <v-row v-if="!readonly" no-gutters>
      <v-col style="max-width: 350px !important" class="text-center">
        <img
          alt=""
          class="ml-1"
          height="40"
          src="@/assets/arrow_green_down.svg"
        />
        <div class="ml-4">
          <PublishTenderDialog
            :tender="tender"
            :disabled="!mixin.publishTenderEnabled()"
            @updateTab="updateTab"
          />
          <TenderMaterialAfterText :publishTenderEnabled="mixin.publishTenderEnabled()" :isTemplate="isTemplate" />
        </div>
      </v-col>
    </v-row>
    <ClarificationDialog
      v-model="showClarificationEditor"
      :tender="tender"
    />
    <SpecificationEditDialog
      v-model="showSpecificationEditor"
      :tender="tender"
    />
    <SpecificationPreviewDialogWithoutActivator
      v-model="showSpecificationViewer"
      :tender="tender"
    />
    <LoadingSpinner loadingMessage="Vent venligst" :visible="isLoading" />
  </v-container>
</template>

<script setup lang="ts">

import { ref, onMounted, watch, computed } from 'vue';
import {
  LoadingSpinner,
  MaterialDocument,
  MaterialFileInfo,
  useStore,
  TenderMaterialTableV2,
  SimpleInfoDialog,
  PublishTenderDialog,
  RequestForOfferDialog,
  SpecificationPreviewDialogWithoutActivator,
  TenderMaterialIntroText,
  TenderMaterialAfterText,
  SpecificationEditDialog,
  TenderStateEnum,
  TenderMaterialMixin,
  Tender,
  DeepQuestionnaire,
} from '@dims/components';
import { Tender0207 } from '@/models/Tender';
import ClarificationDialog from '@/components/Tender/Clarification/ClarificationDialog.vue';
import { questionnaireTabConfig } from '@/services';

/** Presentations of documents when tender is in draft state.
 * Each of the standard files can be replaced by a custom uploaded file
 * Additional custom files can be uploaded
 * For the standard documents, link to questionnaire dialog
 * For custom documents, download.
 */
const emit = defineEmits<{
  isLoaded: [true],
  updateTab: [string]
  }>();
const { readonly = false, tender } = defineProps<{
  readonly?: boolean,
  tender: Tender }>();
const store = useStore();
const showClarificationEditor = ref(false);
const showSpecificationEditor = ref(false);
const showSpecificationViewer = ref(false);
const isLoading = ref(false);

class TenderMaterialMixin0207 extends TenderMaterialMixin {
  isClarifictionQuestionnaireComplete(questionnaire: DeepQuestionnaire) {
    return questionnaire.questions
      .filter((x) => questionnaireTabConfig.getTabs('CustomerClarification').map((t) => t.section).includes(questionnaire.questions.indexOf(x)))
      .every((q) => q.complete) && questionnaire.questions.first().complete;
  }
}
const mixin = ref<TenderMaterialMixin>(new TenderMaterialMixin0207(tender, true, false, true, true));

onMounted(() => {
  emit('isLoaded', true);
});

watch(
  () => tender,
  (newTender: Tender) => {
    mixin.value.tender = newTender;
  },
);

const documentList = computed(() => mixin.value.getDocumentLines());

async function removeFile(file: MaterialFileInfo) {
  isLoading.value = true;
  try {
    await mixin.value.removeFile(file);
  } catch (e) {
    console.error(e);
    store.setSnackbarText('Kan ikke fjerne fil.');
  } finally {
    isLoading.value = false;
  }
}

function onEdit(doc: MaterialDocument) {
  if (doc.kind === 'specification') {
    if (doc.key === 'clarification') {
      showClarificationEditor.value = true;
    } else {
      showSpecificationEditor.value = true;
    }
  }
}

function onPreview(doc: MaterialDocument) {
  if (doc.kind === 'specification') {
    showSpecificationViewer.value = true;
  }
}

function updateTab(tabName: string) {
  emit('updateTab', tabName);
}

async function setDocumentDoneStatus(doc: MaterialDocument, completed: boolean) {
  isLoading.value = true;
  try {
    await mixin.value.setDocumentDoneStatus(doc, completed);
  } catch (e) {
    console.error('Could not update tender', e);
    store.setSnackbarText('Kan ikke markere dokument som færdigt.');
  } finally {
    isLoading.value = false;
  }
}

const clarificationDone = computed(() => (tender as Tender0207).data.clarificationDone);

const isTemplate = computed(() => tender.state === TenderStateEnum.Template);

const uploadModel = computed(() => mixin.value.getUploadModel());

</script>
