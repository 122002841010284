<template>
  <v-card>
    <v-row no-gutters>
      <v-col class="scroller" :style="scrollerStyle">
        <v-card class="card">
          <v-card-text>
            <h2>Evalueringsteknisk pris (beregning)</h2>
            <h3 class="pb-2">Generelle data</h3>
            <TcoCalculation
              :table="getTable(commonSection)"
              :headers="getHeaders(commonSection)"
              :hoveredTag="hoveredTag"
              :tags="[
                { row: 1, cell: 3, value: 'E' },
              ]"
            />
            <h3 class="pb-2">Samlet beregning</h3>
            <v-row>
              <v-col>
                <TcoCalculation
                  :table="getTable(totalsSection)"
                  :headers="getHeaders(totalsSection)"
                  :hoveredTag="hoveredTag"
                  :tags="[
                    { row: 0, cell: 3, value: 'A' },
                    { row: 1, cell: 3, value: 'T' },
                    { row: 2, cell: 3, value: 'B' },
                  ]"
                />
              </v-col>
              <v-col cols="auto">
                <TagInterval tag="B" :hoveredTag="hoveredTag" :start="1" :end="3" />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card color="#fff9ef" class="fill-height" flat>
          <v-card-text ref="description">
            <TcoCalculationDescription @updateHoveredTag="updateHoveredTag" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup lang="ts">
import { ref, onMounted, nextTick } from 'vue';
import { TcoCalculationTable, TagInterval } from '@dims/components';
import TcoCalculationDescription from './TcoCalculationDescription.vue';
import TcoCalculation from './TcoCalculation.vue';

const { tables } = defineProps<{
  tables: Record<string, TcoCalculationTable> }>();
const scrollerStyle = ref({ maxHeight: undefined as string | undefined });
const hoveredTag = ref<string>();

const totalsSection = 'Totals';
const commonSection = 'CommonData';

function updateHoveredTag(tag: string) {
  hoveredTag.value = tag;
}

const description = ref<Element>();

onMounted(() => {
  // Set height of left column to height of description
  // We wait for next tick because mounted() does not guarantee that the DOM has finished rendering
  void nextTick(() => {
    if (description.value) {
      const height = description.value.clientHeight;
      scrollerStyle.value.maxHeight = `${height}px`;
    }
  });
});

const headers: Record<string, {value: string, tag: string}[]> = {
  Totals:
    [
      {
        value: 'Beskrivelse',
        tag: '',
      },
      {
        value: '',
        tag: '',
      },
      {
        value: '',
        tag: '',
      },
      {
        value: 'Total',
        tag: '',
      },
    ],
  CommonData:
    [
      {
        value: 'Beskrivelse',
        tag: '',
      },
      {
        value: '',
        tag: '',
      },
      {
        value: '',
        tag: '',
      },
      {
        value: 'Værdi',
        tag: '',
      },
    ],
};

function getTable(section: string) {
  const table = tables[section];
  if (!table) {
    throw new Error();
  }
  return table;
}

function getHeaders(section: string) {
  const header = headers[section];
  if (!header) {
    throw new Error();
  }
  return header;
}

</script>

<style scoped>

.card {
  margin: 2em;
  background: rgb(var(--v-theme-canvas-base)) !important;
}
h2 {
  color: rgb(var(--v-theme-primary));
  margin-bottom: 1em;
  margin-top: 0em;
  text-transform: uppercase;
}

.scroller {
  overflow-y: scroll !important;
}
</style>
