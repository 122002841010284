<template>
  <div>
    <div class="d-flex flex-row">
      <h3>Titel</h3>
      <DimsTooltip>
        Giv en kort og præcis titel til {{ createAsTemplate ? 'din skabelon' : 'dit indkøb' }}.
        Titlen må ikke indeholde navne på specifikke produkter, producenter eller leverandører. Du kan ændre titlen senere.
      </DimsTooltip>
    </div>
    <EditDescription :store="store" :createAsTemplate="createAsTemplate" />
  </div>
  <AwardCriteria />
</template>

<script setup lang="ts">
import { useStore, EditDescription } from '@dims/components';
import AwardCriteria from './steps/AwardCriteria.vue';

defineProps<{
  /** when set to true it will create a template instead of a draft */
  createAsTemplate?: boolean }>();
const store = useStore();

</script>
<style scoped>
h3 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.tooltip-content {
  max-width: 30vw;
}
</style>
