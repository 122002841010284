import { Calculator, Offer, TenderStateEnum, Tender, OfferEvaluation, DraftTender } from '@dims/components';
import { Offer0207 } from '@/models/Offer';
import { Tender0207, TenderData0207 } from '@/models/Tender';

export const getTotalPoints = (offer: Offer0207, _tender: Tender0207): number => offer.data.bpqScore ?? 0;

export const tenderStub: DraftTender = {
  data: {
    requestForOfferDone: false,
    clarificationDone: false,
    specificationDone: false,
    deliveryAgreementDone: false,
  },
  agreementName: 'dummy',
  state: TenderStateEnum.Prepare,
};

/* Expose the agreement specific calculations in a generic way to be used by shared components */
export class Calculator0207 implements Calculator {
  totalCost(offer: Offer0207, _tender: Tender): number {
    return (offer.data.switchingCosts ?? 0) + (offer.data.tcoCalculationResult?.tcoPrice ?? 0);
  }

  sortByCustomRanking(_offers: Offer[]): Offer[] {
    throw new Error('Custom ranking not supported - Method not implemented.');
  }

  calculatePrice(offer: Offer, _tender: Tender0207): number {
    return offer.contractSum ?? 0;
  }

  sortByPrice(_offers: Offer[], _tender: Tender): Offer[] {
    return _offers.sort(
      (a, b) => (a.contractSum ?? 0)
        - (b.contractSum ?? 0),
    );
  }

  sortBPQBestFirst(offers: Offer0207[], tender: Tender0207, _offersEvaluations: OfferEvaluation[]) {
    return offers.sort(
      // should be negative if a is the best offer, since we want best first
      // since the lowest price is best, result is price(a) - price(b)
      (a, b) => getTotalPoints(b, tender)
        - getTotalPoints(a, tender),
    );
  }

  /// Check if pointscore is below minimum. Agreement specific rule.
  isScoreBelowConditionalThreshold_BackendCalculation(_offer: Offer0207) {
    // This threshold is not used for 02.07
    return false;
  }

  /// Offers with points below a certain threshold are marked as unconditional. Remove later as described in #13384
  isScoreBelowConditionalThreshold_FrontendCalculation(_offer: Offer, _tender: Tender) {
    // This threshold is not used for 02.07
    return false;
  }

  contractSum(offer: Offer0207, _tender: Tender) {
    return offer.contractSum ?? 0;
  }

  /** Shown in 'Samlet score' column for BPQ evaluation
   * should represent the factor used for ordering the offers */
  bpqScoreText(offer: Offer0207, tender: Tender0207, _conditionalOffers: Offer[], _offerEvaluation?: OfferEvaluation) {
    const totalPoints = getTotalPoints(offer, tender);
    if (totalPoints > 0) return `${totalPoints} point`;
    return 'Kvalitets-score ikke indtastet';
  }
}

export function getCalculator() {
  return new Calculator0207();
}

export const getEvaluationSpan = (
  tenderData: TenderData0207,
  allPrices: number[],
  evaluationSpan: number,
  smallestPrice: number,
): number => {
  let result = evaluationSpan;
  const evaluationSpanExtensionPercentage = (tenderData.bpqPriceEvaluationSpanExtensionPercentage ?? 0) / 100;
  const maxPrice = smallestPrice * (1 + evaluationSpan);
  if (allPrices.filter((p) => p > maxPrice).length > allPrices.length / 2) {
    result += evaluationSpanExtensionPercentage;
    result = getEvaluationSpan(tenderData, allPrices, evaluationSpan, smallestPrice);
  }
  return result;
};

export const qualityRatioPercentage = (tender: Tender0207) => {
  const td = tender.data;
  if (td.bpqRatioPercentage === 'Anden procentdel') {
    return td.bpqCustomWeightingQualityCriteriaPercentage ?? 0;
  }
  return parseFloat(td.bpqRatioPercentage ?? '0');
};
